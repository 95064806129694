import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';

const WarningPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.warning.light,
  borderLeft: `4px solid ${theme.palette.warning.main}`,
  color: theme.palette.warning.contrastText,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}));

const HolidayMessage: React.FC = () => {
  const currentDate = new Date();
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  
  const formatDate = (date: Date): string => {
    return date.toLocaleDateString('sv-SE', { month: 'long', day: 'numeric', year: 'numeric' });
  };

  return (
    <WarningPaper elevation={0}>
      <WarningIcon color="warning" style={{ marginRight: '8px' }} />
      <Box>
        <Typography variant="h6" component="h2" gutterBottom>
          Semestermeddelande
        </Typography>
        <Typography variant="body1">
          Vi har semester från {formatDate(currentDate)} till {formatDate(endOfMonth)}.
        </Typography>
        <Typography variant="body1">
          Vi är tillbaka för att hjälpa er efter vår ledighet. Tack för er förståelse!
        </Typography>
      </Box>
    </WarningPaper>
  );
};

export default HolidayMessage;